@import "./../main.module.scss";
@import "./../data/themes.scss";

.headText > h1 {
  color: #fd8298;
  font-family: "Montserrat Bold";
  text-transform: uppercase;
  font-size: 30px;
  width: 2em;
}
.middleNoUl {
  @extend .col-3;
  display: flex;
  justify-content: center;
  height: 70px;
  width: 177px;
  top: 0;
}
.navbarContainer {
  height: 64px;
  position: fixed;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  z-index: 50;
  background-color: rgba(253, 130, 152, 0.8);
  border-radius: 0px 0px 10px 10px;
  @include media("<800px") {
    width: 80%;
  }
  @include media("<tablet") {
    width: 100%;
  }
}

.ulMenu {
  @extend .col-4;
  list-style: none;
  margin-bottom: 0;
  margin-top: 0;
  display: flex;
  flex-direction: row;
  padding-left: 0 !important;
  align-items: center;
  width: 34%;
  max-width: 375px;
  justify-content: space-evenly;
}
.fadeContainer {
  width: 100%;
  height: auto;
}
.fadeBack {
  width: 100%;
  height: auto;
}
.ulMenuStart {
  @extend .ulMenu;
  @include media("<800px") {
    display: none;
  }
}
.ulMenuEnd {
  @extend .ulMenu;
  @include media("<800px") {
    display: none;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.burgerMenu {
  display: none;

  @include media("<800px") {
    background-color: transparent;
    border-radius: 1px;
    font-size: 1.2em;
    font-weight: 500;
    opacity: 0;
    z-index: 24;
    transition: 0.6s ease-in;
    animation: fadeIn 0.5s;
    -webkit-animation: fadeIn 0.5s;
    -moz-animation: fadeIn 0.5s;
    -o-animation: fadeIn 0.5s;
    -ms-animation: fadeIn 0.5s;

    width: 100%;
    margin-top: 2.2vh;
    position: absolute;
    top: calc(-8px + 12vh);
  }
}
.activeBurger {
  @extend .burgerMenu;
  display: none;
  @include media("<800px") {
    display: block;
    opacity: 1;
    align-self: center;
    padding: 0 25px;
  }
}
.ulMobile {
  list-style: none;
  padding: 19px;
  text-align: center;
  display: flex;
  flex-direction: column;
}
.liMobile {
  color: #00739e;
  font-weight: bolder;
  margin-top: 5%;
  align-self: self-start;

  @include media("<tablet") {
    font-size: calc(20px + 33%);
  }
  @include media(">=tablet", "<desktop") {
    font-size: 2.3vh;
  }
}

.liItem {
  font-weight: 600;
  cursor: pointer;
  font-size: 16px;
  color: white !important;
  margin: 0 1vw;
  border-bottom: 2.5px solid transparent;
  white-space: nowrap;
  &:hover {
    animation: borderFade 0.4s;
  }
  &:hover {
    border-bottom: 2.5px solid white;
  }
  @keyframes borderFade {
    0% {
      border-bottom: 2.5px solid transparent;
    }
    100% {
      border-bottom: 2.5px solid white;
    }
  }
}
.HashLinkStyle {
  color: white !important;
  font-family: "Montserrat Regular";

  @include media("<800px") {
    color: #00739e !important;
  }

  text-decoration: none;
}
.navLogo {
  top: 0;
  height: 100%;
}
.burgerElement {
  display: initial;
  z-index: 24;
  height: 36px;
  width: 31px;
  transition: 0.5s;
  align-self: center;
}

.bar1,
.bar2,
.bar3 {
  @include media("<tablet") {
    width: 28px;
    height: 2.5px;
    background-color: map-get($map: $colors, $key: blackSolid);
    margin: 5px 0;
    transition: 0.8s;
    background-color: white;
  }

  @include media(">=tablet", "<800px") {
    width: 33px;
    height: 3.5px;
    background-color: white;
    margin: 5px 0;
    transition: 0.8s;
  }
}

.bar1End {
  @extend .bar1;
  background-color: #fd8298;
}
.bar2End {
  @extend .bar2;
  background-color: #fd8298;
  transform: translate(2px, 0px);
  -webkit-transform: translate(2px, 0px);
}
.bar3End {
  @extend .bar3;

  background-color: #fd8298;
  transform: translate(4px, 0px);
  -webkit-transform: translate(4px, 0px);
}

.menuMobileContainer {
  display: none;
  @include media("<800px") {
    display: flex;
    width: 80px;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 0%;
    margin-top: 13px;
  }
}

.backDropBackground {
  display: none;

  @include media("<800px") {
    background-color: white;
    width: 100vw;
    position: absolute;
    height: 200vh;
    z-index: 8;
  }
}
.active {
  @extend .backDropBackground;
  display: initial;
}
.phoneIcon {
  width: 30px;
  filter: invert(1);
}
.phoneContainer {
  position: absolute;
  right: 5%;
  cursor: pointer;
}
