@import "./../main.module.scss";

.carousel {
  overflow: hidden;
  width: 100%;
  height: 725px;
}

.inner {
  white-space: nowrap;
  transition: transform 0.6s;
  user-select: none;
  width: 80%;
}

.carouselItem {
  display: inline-flex;
  justify-content: center;
}

.leftArrow {
  position: absolute;
  left: 17vw;
  cursor: pointer;
  bottom: 55%;
}

.rightArrow {
  position: absolute;
  right: 17vw;
  cursor: pointer;
  bottom: 55%;
}

.underText {
  width: 90%;
  font-size: 10px;
  display: flex;
  margin: 22px auto;
}
