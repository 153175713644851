@import './../main.module.scss';

.backDrops {
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: 300;
  background-color: rgba(0, 0, 0, 0.527);
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  top: 0;

  @include media('<tablet') {
    width: 100vw;
  }
}
.closePop {
  @include media('<800px') {
    right: 10vw;
    height: 30px;
    border-radius: 50%;
    position: absolute;
    top: 20px;
    z-index: 1000;
    background-color: #00739e;
    color: white;
    font-weight: bolder;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
  }
}

.offersBlock {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 55px;
}
.offerList {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  column-gap: 21px;
  width: 100%;
  padding: 32px 0;
  @include media('>=tablet', '<desktop') {
    column-gap: 12px;
  }
}
.underText {
  font-size: 16px;
  width: 50%;
  @include media('>=tablet', '<desktop') {
    width: 90%;
  }
  @include media('<tablet') {
    width: 90%;
    font-size: 10px;
  }
}
