@import "./../main.module.scss";

.newsletterContainer {
  width: 100%;
  max-width: 960px;
  height: 480px;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 300;
  background-color: rgb(255, 255, 255);
  display: flex;
  flex-direction: row;
  transition: cubic-bezier(0.075, 0.82, 0.165, 1);
  border-radius: 15px;
  overflow: hidden;
  transition: all ease-in-out 1s;
  -webkit-transition: all ease-in-out 1s;
  -moz-transition: all ease-in-out 1s;
  -o-transition: all ease-in-out 1s;

  @include media("<800px") {
    height: 100vh;
    flex-direction: column;
  }
}

.loader {
  border: 5px solid #00739e;
  border-top: 5px solid #f3f3f3;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}

.imageFeatured {
  width: 480px;
  height: 100%;

  background-image: url("./../media/newsletter/newsletterPopup2.jpg");
  object-fit: cover;
  background-position: center;
  transition: width ease-in-out 1;
  -webkit-transition: width ease-in-out 1s;
  -moz-transition: width ease-in-out 1s;
  -o-transition: width ease-in-out 1s;

  @include media("<800px") {
    position: absolute;
    width: 100vw;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
}
.imageFeaturedTransition {
  @extend .imageFeatured;
  width: 960px;
  z-index: 900;

  @include media("<800px") {
    width: 100%;
    min-width: 312px;
  }
}
.containerWrap {
  max-width: 420px;
  transform: translate(0%, 5%);
  position: absolute;
  align-self: center center;
  padding: 10px 10px;

  @include media("<800px") {
    transform: translate(0%, 34%);
    background-color: white;
    padding: 36px 24px;
    width: 92%;
  }
}
.rightPanel {
  min-width: 480px;
  justify-content: center;
  display: flex;
  position: relative;
  @include media("<800px") {
    min-width: 296px;
  }
}
.imageSubscribed {
  @extend .rightPanel;
  position: absolute;
  right: 0px;

  @include media(">=600px", "<800px") {
    width: 90%;
    max-width: 350px;
    min-width: 392px;
  }
}
.title {
  font-weight: bolder;
  color: #00739e;
  font-size: 45px;
  font-family: "Montserrat Bold";
  margin: 0;
  font-size: 48px;
  margin-top: 24px;
}
.titleSubscribed {
  @extend .title;
  font-size: 64px;
}
.description {
  color: white;
  text-shadow: 2px 2px #b3b3b3;
  font-size: 44px;
  font-family: "Autography";
  font-weight: bold;
}
.descriptionSubscription {
  color: white;
  text-shadow: 2px 2px #b3b3b3;
  font-size: 44px;
  font-family: "Montserrat Regular";
  font-weight: bold;
}
.textArea {
  margin: 50px auto 0;
}
.roundInformation {
  border-radius: 50%;
  background-color: #00739e;
  color: white;
  width: 30px;
  height: 30px;
}
.textContain {
  width: 100%;
  margin: auto;
}
input[type="inputNewsletter"] {
  border-radius: 5px;
  border: 1.5px solid #00739e;
  height: 48px !important;
  width: 100%;
  text-indent: 10px;

  &:focus {
    outline: 1.8px solid #00739e;
    border: 2.5px solid #00739e;
  }

  &::placeholder {
    background-color: white;
  }
}
.inputArea {
  display: flex;
  flex-direction: column;
  column-gap: 20px;
  row-gap: 10px;
  @include media("<800px") {
    width: 100%;
    margin: auto;
  }
}
input[type="checkboxTerm"] {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}
.termsText {
  margin-bottom: 0;
  line-height: 12px;
  font-size: 10px;
  font-weight: bold;
  text-align: start;
}
.termArea {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  max-width: 500px;
  margin: 10px 0;
}

.newsletterText {
  line-height: 17px;
  font-family: "Montserrat Regular";
  font-weight: 300;
  font-size: 14px;
}
.iconInfo {
  margin-bottom: 20px;
}
.closeButton {
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
}
.newsSubscribeButton {
  width: 100%;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #00739e;
  border-radius: 5px;
  color: white;
  font-weight: 500;
  border: none;
  margin-top: 20px;
  @include media("<800px") {
    width: 100%;
    background-color: #ffffff;
    color: #00739e;
    border: #00739e 2px solid;
    font-weight: bold;
  }
}
.invalidEmail {
  border: 3px solid red !important;
}
.invalidEmail:focus {
  border: 2px solid red !important;
  outline: 2px solid red !important;
}

@keyframes slowlyFadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.alreadyErrorText {
  font-size: 12px;
  color: red;
  display: flex;
  align-self: flex-start;
  animation: slowlyAppear 0.3s;
  @keyframes slowlyAppear {
    from {
      font-size: 0px;
    }
    to {
      font-size: 12px;
    }
  }
}
