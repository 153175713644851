@import './../main.module.scss';

.containerRadio {
  position: relative;
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 12px;
  -webkit-user-select: none;
  user-select: none;
  @extend .col-4;

  .checkmark {
    height: 24px;
    width: 24px;
    background-color: white;
    border-radius: 50%;
    border: 2px solid #fd8298;
    margin-right: 2px;
  }
  .checkmark::after {
    content: '';
    position: absolute;
    display: none;
  }
  .checkmark:hover {
    background-color: #fd8298;
  }

  input:checked ~ .checkmark {
    background-color: green;
  }
  input:checked ~ .checkmark:after {
    display: block;
  }

  .checkmark::after {
    top: 9px;
    left: 9px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: white;
  }
  input {
    opacity: 0;
  }
}

.containerCheckbox {
  position: relative;
  width: auto;
  display: flex;
  justify-content: normal;
  align-items: center;
  cursor: pointer;
  font-size: 12px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 0;

  @extend .col-4;
  .checkmark {
    height: 24px;
    width: 24px;
    background-color: white;
    border-radius: 5px;
    border: 2px solid #fd8298;
    margin-right: 2px;
  }

  input {
    opacity: 0;
  }
}
input[type='checkbox'] {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}
input[type='radio'] {
  clip: rect(0 0 0 0);
  clip-path: inset(20%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
  border-radius: 50%;
}
.checkbox {
  display: inline-block;
  height: 20px;
  width: 20px;
  background: #fff;
  border: 1px solid #FD8298;
  margin-right: 4px;
  min-width: 20px;
  border-radius: 5px;
}
.checkboxBlue {
  @extend .checkbox;
  border: 1.5px solid #00739e;
}
.checkboxRound {
  @extend .checkbox;
  border-radius: 10px;
}

.checkboxactive {
  @extend .checkbox;
  border-color: #fd8298;
  background-color: #fd8298;

  background-image: url('https://firebasestorage.googleapis.com/v0/b/brasovromanticapartment.appspot.com/o/checkedMark.svg?alt=media&token=4084d18b-5806-44be-8b00-3b77731f564c'),
    linear-gradient(0, #fd8298 100%, #fd8298 100%);
  border-color: #fd8298;

  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.checkboxactiveRound {
  border-color: #fd8298;
  background-color: #ffffff;
  border-color: #fd8298;

  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.checkboxactiveRound::after {
  content: '';
  color: #fd8298;
  background-color: #fd8298;
  position: absolute;
  margin: auto;
  margin-left: 3px;
  margin-top: 3px;
  width: 12px;
  height: 12px;
  border-radius: 40px;
}
.checkboxblueActive {
  @extend .checkboxactive;
  background-image: url('https://firebasestorage.googleapis.com/v0/b/brasovromanticapartment.appspot.com/o/checkedMark.svg?alt=media&token=4084d18b-5806-44be-8b00-3b77731f564c'),
    linear-gradient(0, #00739e 100%, #00739e 100%);
  border-color: #00739e;
  background-color: #00739e;
}

.labelRow {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 50%;
  padding: 5px;
  font-size: 12px;
  font-weight: 600;
  text-align: start;
  align-items: center;
  font-family: 'Montserrat Regular';
  cursor: pointer;

  @include media('>=tablet', '<desktop') {
    line-height: 15px;
  }
  @include media('<tablet') {
    font-size: 13px;
  }
}

.termRow {
  @extend .labelRow;
  width: auto;
}
