@import "./../main.module.scss";

.footerContainer {
  width: 100%;

  background: rgb(253, 130, 152);
  background: linear-gradient(
    180deg,
    rgba(253, 130, 152, 1) 0%,
    rgba(253, 130, 152, 0.7900910364145658) 100%
  );

  display: flex;
  justify-content: center;
  align-items: center;
}
.logoStyle {
  width: 141px;
  height: auto;
  display: table;
  margin: auto;
}
.footerItems {
  margin: 30px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  row-gap: 20px;
}
.iconFooter {
  width: 30px;
  height: 30px;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }
}

.HashLinkStyle {
  color: white !important;
  text-decoration: none;
  font-weight: 600;
  margin-bottom: 15px;
}

.socialGroup {
  display: flex;
  flex-direction: row;
  column-gap: 50px;
  justify-content: center;
}

ul {
  padding: 0 !important;
  list-style: none;
  display: flex;
  justify-content: space-around;
  align-items: center;
  column-gap: 24px;
  white-space: nowrap;
  column-gap: calc(15px + 0.3vw);

  @include media("<tablet") {
    flex-direction: column;
    column-gap: 12px;
  }
}
ul li {
  color: white;
  font-weight: 600;
  cursor: pointer;
  margin-top: 10px;
  white-space: nowrap;
}
.longLine {
  border-bottom: 2px solid white;
  border-top: 2px solid white;
  padding: 20px 0;
  width: 450px;
  display: table;
  margin: auto;

  @include media("<tablet") {
    width: 93vw;
  }
}
.pFooter {
  color: white;
  margin: 0;
}
.copyrightItem {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  flex-flow: wrap;
  @include media("<tablet") {
    flex-direction: column;
  }
}
.copyrightItem p {
  color: white;
  margin: 0;
  font-size: calc(7px + 0.4vw);
  flex-flow: wrap;
  @include media("<tablet") {
    font-size: 12px;
    padding: 0px 20px;
  }
  @include media(">=tablet") {
    // font-size: 11px;
  }
}
.bottomLogo {
  width: 84px;
  margin: 0 5px;
  @include media("<tablet") {
    margin-top: 10px;
  }
}
