@import "./../main.module.scss";

.bookTextTop {
  color: #fd8298;
  font-family: "Montserrat Bold";
  font-weight: bolder;
  font-size: calc(35px + 1.2vw) !important;
  margin: 2px 0px;
}
.bookTextDown {
  font-family: "Autography";
  font-size: calc(30px + 1.7vw) !important;
  margin: -10px 0 0;
  color: #fd8298;
}

.cardboardWhy {
  margin: 15px auto;
  padding-top: 15px;
}
.imageContainer {
  width: 100%;
  @include media("<tablet") {
    flex-shrink: 0;
    min-width: 100%;
    min-height: 100%;
    margin-left: -237px;
    left: 18px;
    width: unset;
  }
}
.imageWrapper {
  @include media("<tablet") {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }
}

.cardBoard {
  width: 90%;
  max-width: 960px;
  min-height: 250px;
  box-shadow: 0px 4px 50px 3px rgba(0, 0, 0, 0.1);
  border-radius: 10px 10px 0px 0px;
  margin-top: -2vw;
  margin-bottom: 115px;
  margin-left: auto;
  margin-right: auto;
  flex-direction: column;
  display: flex;
  z-index: 10;
  position: relative;
  background-color: #ffff;

  @include media(">=desktop") {
  }
  @include media("<desktop") {
    margin-top: -5vw;
  }
}
.answerText {
  font-family: "Montserrat";
  line-height: 19.5px;
  width: 80%;
  margin: 2px auto 50px;
}
.responsiveText {
  @include media("<tablet") {
    font-size: 16px;
  }
}
.facitiliesContainer {
  @extend .row;
  padding: 30px 0;
  row-gap: 30px;
  column-gap: 120px;
  justify-content: center;
  margin: 0 3%;

  @include media(">=tablet", "<desktop") {
    column-gap: 5em;
    margin: 0 auto;
    padding-left: 2% !important;
    padding-right: 2% !important;
  }

  @include media("<tablet") {
    padding: 30px 0px;
    column-gap: calc(5px + 5vw);
  }
}

.activeShowUp {
  .popText {
    opacity: 1;
    transition: opacity 0.3s ease-in-out;
  }
  .thumbPic {
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }
}
.roundCircle {
  @extend .col-4;
  width: 118px;
  height: 118px;
  border-radius: 50%;
  box-shadow: 0px 4px 10px 1px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: default;
  &:hover {
    @extend .activeShowUp;
  }

  @include media("<tablet") {
    width: 87px;
    height: 87px;
  }
}
.thumbPic {
  width: auto;
  height: auto;
  margin: auto;
  @include media("<tablet") {
    width: 72%;
    height: 72%;
  }
}
.popText {
  opacity: 0;
  position: absolute;
  font-weight: bolder;
  color: #00739e;
  margin: 0;
  width: 96px;
  padding: 0 6px;
  @include media("<tablet") {
    font-size: 80%;
  }
}
