@import "./../main.module.scss";

.questionsBlock {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.bookTextTop {
  color: #fd8298;
  font-family: "Montserrat Bold";
  font-weight: bolder;
  font-size: calc(45px + 1.2vw) !important;
  margin: 0px 0px 20px 0px;
}
.bookTextDown {
  font-family: "Autography";
  font-size: calc(40px + 1.7vw) !important;
  margin: -10px 0 0;
  color: #fd8298;
}
.cardsWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 15px;
  margin-top: 30px;
  margin-bottom: 82px;
}
