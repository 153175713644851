@import "./../main.module.scss";

.carousel {
  overflow: hidden;
  width: 100%;
  margin-bottom: -7px;
}

.inner {
  white-space: nowrap;
  transition: transform 0.6s;
  user-select: none;
  width: 50%;
  @include media("<tablet") {
    width: 88%;
  }
  @include media(">=tablet", "<desktop") {
    width: 77%;
  }
}

.carouselItem {
  display: inline-flex;
  justify-content: center;
}

.leftArrow {
  position: absolute;
  z-index: 10;
  left: 5vw;
  top: 50vh;
  cursor: pointer;
  @include media("<tablet") {
    display: none;
  }
  @include media(">=tablet", "<desktop") {
    display: none;
  }
}

.rightArrow {
  position: absolute;
  z-index: 10;
  right: 5vw;
  top: 50vh;
  cursor: pointer;
  @include media("<tablet") {
    display: none;
  }
  @include media(">=tablet", "<desktop") {
    display: none;
  }
}
