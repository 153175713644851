@import "./../main.module.scss";

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 60px;
}

.title {
  color: #fd8298;
  font-family: "Montserrat Bold";
  font-weight: bolder;
  font-size: 3rem !important;
  margin: 0;
}

.subTitle {
  font-family: "Autography";
  font-size: 4rem !important;
  margin: -10px 0 0;
  color: #fd8298;
}

.photo {
  width: 49vw;
  image-rendering: optimizeSpeed;
  @include media("<tablet") {
    width: 85vw;
  }
  @include media(">=tablet", "<desktop") {
    width: 76vw;
  }
}

.leftArrow {
  width: 25px;
  height: 25px;
  display: flex;
  align-self: center;
  cursor: pointer;
  @include media("<tablet") {
    display: none;
  }
}

.rightArrow {
  width: 25px;
  height: 25px;
  display: flex;
  align-self: center;
  cursor: pointer;
  @include media("<tablet") {
    display: none;
  }
}

.titleContainer {
  display: flex;
  justify-content: center;
  align-self: center;
  flex-direction: row;
  width: 100%;
}

.textContainer {
  display: flex;
  flex-direction: column;
  padding-left: 10vw;
  padding-right: 10vw;
}

.carouselContainer {
  width: 100%;
  height: 38vw;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 50px 3px rgba(0, 0, 0, 0.1);
  @include media("<tablet") {
    height: 65vw;
  }
  @include media(">=tablet", "<desktop") {
    height: 60vw;
  }
}
