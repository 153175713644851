@import "./../main.module.scss";

.rowCustom {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}

.staticStyle {
  background-color: white;
  width: auto;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 10px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  position: relative;
}
.floatingStyle {
  background-color: white;
  width: auto;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: fixed;
  z-index: 300;
  border-radius: 10px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  position: relative;
}
.bookTextTop {
  color: #fd8298;
  font-family: "Montserrat Bold";
  font-weight: bolder;
  font-size: 3rem !important;
  margin: 0;
}
.bookTextDown {
  font-family: "Autography";
  font-size: 4rem !important;
  margin: -10px 0 0;
  color: #fd8298;
}
.bookingCard {
  @extend .rowCustom;
  width: 1058px;
  height: 568px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-width: 330px;
  border-radius: 10px;
  @include media("<800px") {
    width: 90vw;
    min-width: 320px;
  }
  @include media("<1109px") {
    width: 93.5vw;
  }
}
.offerOptionNotification {
  font-weight: 400;
  min-height: 50px;
  padding: 0px 13%;
  p {
    margin-bottom: 0;
    line-height: 15px;
  }
  @include media("<800px") {
    display: none;
  }
}
.bookingActions {
  color: #fd8298;
}
.radioGroup {
  display: flex;
  flex-direction: column;
}
.topBook {
  color: #fd8298;
}

.extender {
  @extend .col-12;
  @extend .rowCustom;

  width: 100%;
  height: auto;
  max-height: 55px;
  @include media(">=800px", "<desktop") {
    justify-content: center;
    max-width: 730px;
  }
}
.bottomLine {
  width: 266px;
  height: 2px;
  background-color: #fd8298;
  display: table;
  margin: auto;
  @include media(">=800px", "<desktop") {
    width: 70%;
  }
}
.bookingOverview {
  font-family: "Montserrat Regular";
  color: #00739e;
  font-weight: 400;
  font-size: 24px;
  margin-bottom: 11px;
}
.bookNowButton {
  outline: none;
  background-color: white;
  width: 300px;
  height: 56px;
  border-radius: 50px;
  font-size: 18px;
  font-weight: 500;
  color: #00739e;
  border: 1px solid #00739e;

  &:hover {
    background-color: #00739e;
    color: white;
  }

  @include media(">=800px", "<desktop") {
    width: 100%;
    max-width: 270px;
  }
}

.confirmButton {
  outline: none;
  background-color: #00739e;
  width: 300px;
  height: 56px;
  border-radius: 50px;
  font-size: 18px;
  font-weight: 500;
  color: white;
  border: 1px solid #00739e;

  &:hover {
    background-color: white;
    color: #00739e;
  }
  @include media(">=tablet", "<desktop") {
    width: 224px;
  }
}

.bookCancel {
  @extend .bookNowButton;

  background-color: white;
  border: 1px solid #00739e;
  color: #00739e;
  @include media(">=tablet", "<desktop") {
    width: 224px;
  }
}
.columnS {
  @extend .col-4;
  @include media("<800px") {
    display: none;
  }
}
.columnM {
  @extend .col-4;
  @include media("<800px") {
    width: 100%;
  }
}
.orderActions {
  @include media(">=800px") {
    flex-direction: row;
    display: flex;
    margin: auto;
    column-gap: 40px;
  }
  @include media(">=desktop") {
    flex-direction: row;
    display: flex;
    margin: auto;
    column-gap: 40px;
  }
}
.containerSider {
  @include media("<800px") {
    display: none;
  }
}

.genericMobile {
  @include media("<800px") {
    width: 100%;
    background-color: white;
    height: 100vh;
  }
}
.closeButton {
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
}
