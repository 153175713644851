@import "./../main.module.scss";

.container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.reviewContainer {
  width: 960px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @include media(">=tablet", "<desktop") {
    justify-content: center;
    flex-direction: column;
    width: 100%;
  }
  @include media("<tablet") {
    justify-content: center;
    flex-direction: column;
    width: 100%;
  }
}

.bookingLine {
  position: absolute;
  width: 960px;
  height: 3px;
  background-color: #273b7d;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  @include media(">=tablet", "<desktop") {
    width: 100%;
  }
  @include media("<tablet") {
    width: 100%;
  }
}

.reviewLine {
  position: absolute;
  width: 960px;
  height: 3px;
  background-color: #ff385c;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  @include media(">=tablet", "<desktop") {
    width: 100%;
  }
  @include media("<tablet") {
    width: 100%;
  }
}

.iconContainer {
  width: 50px;
}

.review {
  width: 231px;
  height: 231px;
  display: flex;
  background-repeat: no-repeat;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1;
  background-size: cover;
  margin-right: 3vw;
  @include media(">=tablet", "<desktop") {
    margin-right: 0;
    margin-bottom: 10vw;
    width: 15vw;
    height: 15vw;
  }
  @include media("<tablet") {
    margin-right: 0;
    margin-bottom: 15vw;
    width: 50vw;
    height: 50vw;
  }
}

.star {
  width: 242px;
  height: 231px;
  display: flex;
  background-repeat: no-repeat;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1;
  background-size: cover;
  @include media(">=tablet", "<desktop") {
    margin-bottom: 9vw;
    width: 17.7vw;
    height: 16.9vw;
  }
  @include media("<tablet") {
    margin-bottom: 15vw;
    width: 55.4vw;
    height: 52.9vw;
  }
}

.bookingText {
  font-size: 35px;
  margin-bottom: 3.5vw;
  font-family: "Montserrat Bold";
  color: #273b7d;
  margin-left: 1.5vw;
  @include media(">=tablet", "<desktop") {
    margin-left: 0;
    font-size: 3vw;
  }
  @include media("<tablet") {
    margin-left: 0;
    font-size: 4vw;
    width: 60%;
  }
}

.reviewText {
  font-size: 35px;
  margin-bottom: 3.5vw;
  font-family: "Montserrat Bold";
  color: #ff385c;
  margin-right: 1.5vw;
  @include media(">=tablet", "<desktop") {
    display: none;
  }
  @include media("<tablet") {
    display: none;
  }
}

.reviewTextMobile {
  display: none;
  font-size: 1.8vw;
  margin-bottom: 3.5vw;
  font-family: "Montserrat Bold";
  color: #ff385c;
  margin-right: 1.5vw;
  @include media(">=tablet", "<desktop") {
    margin-right: 0;
    display: block;
    font-size: 3vw;
  }
  @include media("<tablet") {
    font-size: 4vw;
    width: 60%;
    margin-right: 0;
    display: block;
  }
}

.scoreText {
  font-size: 90px;
  font-family: "Montserrat Bold";
  color: white;
  @include media(">=tablet", "<desktop") {
    font-size: 6vw;
  }
  @include media("<tablet") {
    font-size: 20vw;
  }
}

.outOffText {
  font-size: 25px;
  color: white;
  font-family: "Montserrat Bold";
  margin-top: -2.2vw;
  margin-bottom: 1vw;
  @include media(">=tablet", "<desktop") {
    font-size: 1.5vw;
  }
  @include media("<tablet") {
    margin-top: -4vw;
    font-size: 5vw;
  }
}
