.carousel {
  overflow: hidden;
  width: 100%;
}

.inner {
  white-space: nowrap;
  transition: transform 0.6s;
  cursor: grab;
  user-select: none;
}
.innerActive {
  white-space: nowrap;
  transition: transform 0.6s;
  cursor: grabbing;
  user-select: none;
}

.carouselItem {
  display: inline-flex;
  justify-content: center;
}

.indicators {
  display: flex;
  justify-content: center;
}
