@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

.date-picker {
  box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
}

.date-picker ::-webkit-scrollbar {
}

.date-picker ::-webkit-scrollbar-track {
  background: black;
}

.date-picker ::-webkit-scrollbar-thumb {
  background: red;
}

.titleContainer {
  background-color: #fd8298;
  align-self: center;
  justify-content: center;
  display: flex;
  width: fit-content;
  margin: 0 auto;
  padding: 2px 15px 2px 15px;
  border-radius: 0px 0px 10px 10px;
}

.titleText {
  font-size: 18px;
  color: white;
}

.reserveButton {
  background-color: #fd8298;
  color: white;
  margin: 0 auto;
  display: flex;
  align-self: center;
  justify-content: center;
  align-items: center;
  display: flex;
  width: 320px;
  height: 56px;
  border-radius: 50px;
  margin-bottom: 15px;
  user-select: text;
  cursor: pointer;
}

.dayContainerS {
  display: flex;
  align-items: center;
  font-family: "Inter";
  justify-content: center;
  border-radius: 4px;
  font-family: "Inter";
  font-style: normal;
  font-weight: bolder;
  font-size: 12px;
  line-height: 15px;
  display: flex;
  align-items: center;
  text-align: center;
  font-feature-settings: "tnum" on, "lnum" on;
  color: #333333;
  height: 48px;
  cursor: pointer;
  border: 1px solid white;

  @media (max-width: 768px) {
    height: 40px;
  }
}

.popupCal {
  position: absolute;
  margin-top: -58px;
  width: 105px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  border: 1.1px solid gray;
  background-color: #efefef;
  visibility: hidden;
  color: black;
}
.dayContainerS:hover {
  background: #f2f2f2;
  border: 1px solid #f2f2f2;
}
.dayContainerS:hover > .popupCal {
  visibility: visible;
}
.monthDays {
  color: #fd8298 !important;
}
.href {
  text-decoration: none;
}
