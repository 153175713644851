@import './../main.module.scss';

.cardboardWhy {
  margin: 15px auto;
  padding-top: 15px;
}
.bookTextTop {
  color: #fd8298;
  font-family: 'Montserrat Bold';
  font-weight: bolder;
  font-size: calc(35px + 1.2vw) !important;
  margin: 2px 0px;
}
.bookTextDown {
  font-family: 'Autography';
  font-size: calc(30px + 1.7vw) !important;
  margin: -10px 0 0;
  color: #fd8298;
}
