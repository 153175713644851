@import "./../main.module.scss";

.offerCardboard {
  display: flex;
  flex-flow: column;
  height: 662px;
  border-radius: 10px;
  border: 1px solid #00739e;
  box-shadow: 0px 4px 50px 3px rgba(0, 0, 0, 0.1);
  width: 343px;
  min-width: 281px;
  @include media("<tablet") {
    width: 95%;
    height: 580px;
    transition: 0.2s ease-in-out;
  }
  @include media(">=tablet", "<desktop") {
    width: 281px;
    min-width: 190px;
    height: 559px;
  }
}
.offerCardboardSecond {
  @extend .offerCardboard;
  width: 309px;
  height: 594px;
  border-radius: 10px;
  border: 1px solid #fd8298;
  min-width: 225px;
  box-shadow: 0px 4px 50px 3px rgba(0, 0, 0, 0.1);
  @include media("<tablet") {
    width: 95%;
    height: 550px;
    transition: 0.2s ease-in-out;
  }
  @include media(">=tablet", "<desktop") {
    width: 225px;
    min-width: 180px;
    height: 491px;
  }
}
.topArea {
  width: 100%;
  height: 120px;
  justify-content: center;
  display: flex;
  align-items: center;
  @include media("<tablet") {
    height: 80px;
  }
}
.title {
  font-weight: 600;
  font-size: 40px;
  color: #00739e;
  @include media(">=tablet", "<desktop") {
    font-size: 25px;
  }
}
.titleSecond {
  @extend .title;
  color: #fd8298;
}
.fineLine {
  width: 80%;
  margin: auto;
  height: 2px;
  background-color: #00739e;
}
.fineLinePink {
  width: 80%;
  margin: auto;
  height: 2px;
  background-color: #fd8298;
}

.middleArea {
  width: 100%;
  height: 346px;
}
.price {
  font-weight: 700;
  font-size: 40px;
  color: #00739e;
  margin-top: 20px;
  margin-bottom: 0;
  font-family: "Montserrat Bold";
  @include media(">=tablet", "<desktop") {
    font-size: 35px;
  }
}
.pricePink {
  @extend .price;
  color: #fd8298;
}
.currency {
  margin-top: 0;
  padding: 0;
  font-size: 16px;
  color: #00739e;
  font-weight: bolder;
  @include media(">=tablet", "<desktop") {
    font-size: 14px;
  }
}
.currencySecond {
  @extend .currency;
  color: #fd8298;
}
.ulList {
  display: flex;
  flex-direction: column;
  margin: 0;
}
.liItem {
  color: #4f4e4e;
  margin: 0;
  font-family: "Montserrat Regular";
  @include media(">=tablet", "<desktop") {
    font-size: 13px;
  }
}
.callToAction {
  border-radius: 50px;
  border: 1px solid #00739e;
  padding: 15px 34px;
  background-color: transparent;
  color: #00739e;
  height: 56px;
  font-weight: bolder;
  &:hover {
    background-color: #00739e;
    color: white;
  }
  @include media(">=tablet", "<desktop") {
    min-width: 50%;
    padding: 15px 20px;
  }
}
.callToActionSecondary {
  @extend .callToAction;
  border: 1px solid #fd8298;
  color: #fd8298;
  &:hover {
    background-color: #fd8298;
  }
}
.bottomArea {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 170px;
  flex: 1 1 auto;
  @include media("<tablet") {
    height: 130px;
  }
}
