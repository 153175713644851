@import "./../main.module.scss";

.container {
  display: flex;
  justify-content: center;
  max-width: 100%;
  position: relative;
  padding: 0;
}

.carouselImage {
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-position: center center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.backDrops {
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: 300;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
}
.textContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  white-space: normal;
  position: absolute;
  transform: translate(0, -40%);
  @include media("<tablet") {
    width: 80%;
    top: 162px;
  }
  @include media(">=tablet", "<desktop") {
    top: 300px;
  }
}

.title {
  display: flex;
  font-size: 3.3vw;
  color: #fd8298;
  font-family: "Montserrat Bold";
  @include media(">=tablet", "<desktop") {
    font-size: 40px;
  }
  @include media("<tablet") {
    font-size: 28px;
  }
}

.description {
  font-size: 3.4vw;
  font-family: "Autography";
  color: #ffffff;
  @include media(">=tablet", "<desktop") {
    font-size: 40px;
  }
  @include media("<tablet") {
    font-size: 22px;
  }
}

.buttonsContainer {
  position: absolute;
  bottom: 9.4vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.reservationContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  @include media("<tablet") {
    margin-top: 10px;
    justify-content: space-around;
    width: 269px;
  }
}

.button {
  border-radius: 50px;
  cursor: pointer;
}

.chatButton {
  border-radius: 50px;
  cursor: pointer;
  position: fixed;
  right: 22px;
  bottom: 40px;
  z-index: 100;
  @include media(">=tablet", "<desktop") {
    right: 42px;
    bottom: 42px;
    position: absolute;
  }
  @include media("<tablet") {
    display: none;
  }
}

.reservationButton {
  background-color: #ffffff;
  width: 320px;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  color: #fd8298;
  border-radius: 50px;
  margin: 15px;
  cursor: pointer;
  @include media(">desktop") {
    &:hover {
      background-color: #fd8298;
      color: #ffffff;
    }
  }
  @include media(">=tablet", "<desktop") {
    background-color: #fd8298;
    color: #ffffff;
  }
  @include media("<tablet") {
    width: 269px;
  }
}

.newsletterButton {
  background-color: rgba(255, 255, 255, 0.2);
  border: 1px solid #ffffff;
  width: 320px;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  color: #ffffff;
  border-radius: 50px;
  cursor: pointer;
  @include media(">tablet") {
    &:hover {
      background-color: #ffffff;
      color: #00739e;
    }
  }
  @include media(">=tablet", "<desktop") {
  }
  @include media("<tablet") {
    width: 269px;
    background-color: #ffffff;
    color: #00739e;
  }
}

.closePop {
  @include media("<800px") {
    right: 10vw;
    height: 30px;
    border-radius: 50%;
    position: absolute;
    top: 20px;
    z-index: 1000;
    background-color: #00739e;
    color: white;
    font-weight: bolder;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
  }
}
