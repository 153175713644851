@import "./../main.module.scss";

.bookingAreaContainer {
  width: 100%;
}

.bookTextTop {
  color: #fd8298;
  font-family: "Montserrat Bold";
  font-weight: bolder;
  font-size: 3rem !important;
  margin: 0;
}
.bookTextDown {
  font-family: "Autography";
  font-size: 4rem !important;
  margin: -10px 0 0;
  color: #fd8298;
}

.bookAction {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 60px;
  column-gap: 33px;

  @include media("<800px") {
    flex-direction: column;
    row-gap: 20px;
  }
}

.airbnbButton {
  width: 196px;
  height: 60px;
  border-radius: 40px;
  border: 2px solid #ff5a5f;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.bookingButton {
  width: 196px;
  height: 60px;
  border-radius: 40px;
  border: 2px solid #499fdd;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.bookingStyle {
  width: 128px;
  height: auto;
  margin: auto;
}
.bookingBlock {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
