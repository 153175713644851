@import "./../main.module.scss";

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 30px;
}

.addCalendar {
  display: flex;
  margin-top: 50px;
  position: relative;
  flex-direction: column;
  font-size: 10px;
  justify-content: center;
  margin-bottom: 50px;

  @include media(">=tablet", "<=desktop") {
    font-size: 8px;
  }
}

.checkInOnly:not(.-selectedStart):not(.-selectedBetween):not(.-selectedEnd):not(.-selected) {
  opacity: 0.5 !important;
  font-weight: bold;
  background-color: (255, 255, 255, 0.5);
}

.checkInOnly:hover:not(.-selectedStart):not(.-selectedBetween):not(.-selectedEnd):not(.-selected) {
  opacity: 1 !important;
}

.checkInOnly:hover::after {
  opacity: 1 !important;
  content: url("../components/NewCalendar/assets/tooltip.svg");
  position: absolute;
  padding-bottom: 70px;
  font-size: 10px;
}

.disabledDays:not(.-selectedStart):not(.-selectedBetween):not(.-selectedEnd):not(.-selected) {
  font-weight: bold;
  text-decoration: line-through;
}

.buttonStyle {
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  background-color: #fd8298;
  border-radius: 10px;
  border: none;
  width: 70%;
  align-self: center;
  padding: 10px 10px 10px 10px;
  margin-bottom: 10px;

  color: map-get($map: $colors, $key: whitePure);
  transition: all 0.5s;
  &:hover {
    background-color: #ffc4cf;
  }
}

.buttonText {
  color: map-get($map: $colors, $key: whiteSmokey);
  font-size: 14px;
  font-family: "Montserrat Regular";

  @include media("<=tablet") {
    font-size: 12px;
  }
  @include media(">=tablet", "<=desktop") {
    font-size: 9px;
  }
}

.askReserveModal {
  position: relative;
  display: flex;
  justify-content: center;
  align-content: center;
  width: 500px;
  height: 200px;
  border-radius: 5px;
  border-color: red;
  text-align: center;
  z-index: 20;
  background-color: map-get($map: $colors, $key: beautyBush);
  flex-direction: column;
  top: 20px;
  left: 10px;
  font-family: "Montserrat Regular";
  @include media("<=tablet") {
    height: 100px;
    width: 300px;
  }
}

.reserveButton {
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  flex-direction: row;
  background-color: #fd8298;
  border-radius: 10px;
  border: none;
  height: 30px;
  width: 80px;
  color: map-get($map: $colors, $key: whitePure);
  transition: all 0.5s;
  margin-left: 20px;
  &:hover {
    background-color: #ffc4cf;
  }

  @include media("<=tablet") {
    height: 30px;
    width: 60px;
  }
  @include media(">=tablet", "<=desktop") {
    height: 60px;
    width: 120px;
  }
}

.flexRow {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 10px;
}

.reserveText {
  font-size: 14px;
  font-family: "Montserrat Regular";
  @include media("<=tablet") {
    font-size: 10px;
  }
  @include media(">=tablet", "<=desktop") {
    font-size: 12px;
  }
}

.reserveMainText {
  font-size: 22px;
  font-family: "Montserrat Regular";
  @include media("<=tablet") {
    font-size: 12px;
  }
  @include media(">=tablet", "<=desktop") {
    font-size: 14px;
  }
}

.calendarButtonContainer {
  flex-direction: row;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
}
.textCalendar {
  font-size: 14px;

  color: red;
  font-weight: 800;

  @include media("<=tablet") {
    font-size: 12px;
  }
  @include media(">=tablet", "<=desktop") {
    font-size: 10px;
  }
}
.calendarFooter {
  justify-content: center;

  display: flex;
  flex-direction: column;
}
