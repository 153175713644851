@import "./../main.module.scss";

.bookTextTop {
  color: #fd8298;
  font-family: "Montserrat Bold";
  font-weight: bolder;
  font-size: 3rem;
  margin: 2px 0px;

  @include media(">=tablet", "<desktop") {
    font-size: calc(12px + 3.3vw);
  }
}
.bookTextDown {
  font-family: "Autography";
  font-size: 4rem;
  margin: -10px 0 0;
  color: #fd8298;
  @include media(">=tablet", "<desktop") {
    font-size: calc(30px + 3.9vw);
  }
  @include media("<tablet") {
    font-size: calc(23px + 6.3vw);
  }
}

.reviewsArea {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  column-gap: 10px;
  row-gap: 20px;
  margin-top: 30px;
  margin-bottom: 30px;
  @include media(">=tablet", "<desktop") {
    width: 100%;
    flex-direction: row;
    margin-top: 30px;
    margin-top: 0px;
  }
  @include media("<tablet") {
    flex-direction: column;
    width: 100%;
    align-items: center;
    margin-bottom: 20px;
    margin-top: 0px;
  }
}

.cardReview {
  width: 472px;
  height: 150px;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  align-items: center;
  border: #fd8298 2px solid;
  border-radius: 100px;
  padding: 11px;
  margin-bottom: 10px;

  @include media(">=767px", "<desktop") {
    width: 374px;
  }
  @include media("<tablet") {
    width: calc(45px + 80vw);
  }
}
.imageAuthor {
  display: flex;
  flex: none;
  width: 130px;
  height: 130px;
  border-radius: 50%;
  border: #fd8298 2px solid;
  background-color: #00739e4d;
}
.commentReview {
  display: flex;
  flex-direction: column;
  padding: 0 14px;
  white-space: normal;
}
.name {
  font-weight: bold;
  text-align: start;
}
.comment {
  line-height: 13.1px;
  text-align: start;
  font-size: 12px;

  @include media("<tablet") {
    font-size: calc(9px + 0.2vw);
    line-height: calc(7px + 1.1vw);
  }
  @include media(">=tablet", "<desktop") {
    font-size: calc(9px + 0.1vw);
    line-height: calc(7px + 0.5vw);
  }
}
.authorInitials {
  font-weight: bolder;
  color: white;
  font-size: 50px;
  font-family: "Montserrat";
  display: table;
  margin: auto;
}

.reviewsSection {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 50px 0px;
}
.controlsAction {
  width: 300px;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 10px;
}

.roundButton {
  width: 12px;
  height: 12px;
  margin: 6px;
  align-self: center;
  background-color: #ff989f;
  border-radius: 50%;
  cursor: pointer;
}
.roundButtonActive {
  width: 14px;
  height: 14px;
  margin: 6px;
  align-self: center;
  background-color: #fd8298;
  border-radius: 50%;
  cursor: pointer;
}
